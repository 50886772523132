$margin-padding: 5rem;
$white: #f7fcff;
body {
  background-color: $white;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 1000px) {
  .outer {
    margin-top: 0rem !important;
    /* padding: 20px; */
    max-width: 1200px;
  }
}
.tilt {
  transform-style: preserve-3d;
  /* transform: perspective(1000px); */
}

.outer {
  margin: auto;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
}

.center-with-margin {
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  max-width: 1200px;
}

section.steal-margin-for-polygon {
  margin-top: 0;
}

section.grab-padding-for-polygon {
  padding-top: $margin-padding;
  padding-bottom: $margin-padding;
  margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h2 {
  font-size: 2.5rem;
  font-weight: 900;
}
h3 {
  font-weight: 900;
  font-size: 2.4rem;
}
a {
  font-size: 1.5rem;
  transition: all 0.3s ease;
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;
}

body {
  /* background: url(./images/logoBackground.svg);
  background-position: 50% 20vh;
  background-size: cover; */
}
/* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
.left {
  animation: 1s ease 0s 1 normal forwards running fadeIn;
}
.right a {
  animation: 1s ease 0.1s 1 normal forwards running enterVertical;
}
.left h1 {
  animation: 1s ease 0s 1 normal forwards running enterVertical;
}
.right a:nth-child(2) {
  img {
    animation-delay: 0.2s;
  }
}
img,
a {
  animation: 1s ease 0s 1 normal forwards running enterVertical;
}
.left h1:nth-child(2) {
  animation-delay: 0.2s;
}
.left h1:nth-child(3) {
  animation-delay: 0.4s;
}
.left h1:nth-child(4) {
  animation-delay: 0.6s;
}
.image-wrapper {
  animation-delay: 0.6s;
}
.skills-text-wrapper p {
  animation: 1s ease 0.15s 1 normal forwards running fadeIn;
}
.skills-text-wrapper h2 {
  animation: 1s ease 0.2s 1 normal forwards running enterVertical;
}
.intro-text-wrapper p {
  animation: 1s ease 0.1s 1 normal forwards running fadeIn;
}
.skills-list .row .skill {
  animation: 1s ease 0.25s 1 normal forwards running enterVertical;
}
.row li:nth-child(2) {
  animation-delay: 0.2s;
}
.portfolio-animation {
  h1 {
    transform: translateY(30px);
    opacity: 0;
    animation: 1s ease 0s 1 normal forwards running enterVertical;
  }
  h2 {
    transform: translateY(30px);
    opacity: 0;
    animation: 1s ease 0.2s 1 normal forwards running enterVertical;
  }
  p {
    animation: 1s ease 0.1s 1 normal forwards running fadeIn;
    opacity: 0;
  }
  img,
  a {
    opacity: 0;
    transform: translateY(30px);
  }
}
@keyframes enterVertical {
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes enterHorizontal {
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
